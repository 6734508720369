<script>
import AOS from 'aos';
import 'aos/dist/aos.css';
import TheIntro from './components/TheIntro.vue';
import TankersOne from './components/tankers/TankersOne.vue';
import TankersTwo from './components/tankers/TankersTwo.vue'
import XRChomsky from './components/chomsky/XRChomsky.vue';
import Mitch from './components/mitch/TheMitch.vue';
import TheFestivals from './components/festivals/TheFestivals.vue';
import ProtestsOne from './components/protests/ProtestsOne.vue';
import ProtestsTwo from './components/protests/ProtestsTwo.vue';
import OrangeTez from './components/tez/OrangeTez.vue'
import EbenDisobey from './components/disobey/EbenDisobey.vue';
import TheOutro from './components/TheOutro.vue';

import { reactive, provide, watch } from 'vue';

export default {
    components: {
      TankersOne,
      TankersTwo,
      TheIntro,
      XRChomsky,
      Mitch,
      TheFestivals,
      ProtestsOne,
      ProtestsTwo,
      OrangeTez,
      EbenDisobey,
      TheOutro,
    },
    data() {
        return {
            inMove: false,
            inMoveDelay: 400,
            activeSection: 0,
            offsets: [],
            touchStartY: 0
        }
    },
    setup() {
      // Initialize slideshowActive with the value from localStorage, if it exists
      let initialSlideshowActive = location.pathname.includes('/slideshow');
      const state = reactive({slideshowActive: initialSlideshowActive})
      provide('state', state)

      // Watch for changes on state.slideshowActive to store in localStorage
      watch(() => state.slideshowActive, (newVal) => {
        localStorage.setItem('slideshowActive', newVal);
      })

      return {
        state
      }
    },
    methods: {
        // Calcaulates the absolute offsets of each section on the page and pushs it into the offsets array!!!!!!
        calculateSectionOffsets() {
            let sections = document.getElementsByTagName('section');
            let length = sections.length;

            for(let i = 0; i < length; i++) {
                let sectionOffset = sections[i].offsetTop;
                this.offsets.push(sectionOffset);
            }
        },

        // Handle the 'mousewheel' event for other browsers
        handleMouseWheel: function(e) {
            if (e.wheelDelta < 30 && !this.inMove) {
                this.moveUp();
            } else if (e.wheelDelta > 30 && !this.inMove) {
                this.moveDown();
            }
            e.preventDefault();
            return false;
        },

        // Handle the 'DOMMouseScroll' event for Firefox
        handleMouseWheelDOM: function(e) {
            if (e.detail > 0 && !this.inMove) {
                this.moveUp();
            } else if (e.detail < 0 && !this.inMove) {
                this.moveDown();
            }
            return false;
        },

        // Move to the previous section or the last section if you're on the first section
        moveDown() {
          this.inMove = true;

          if (this.activeSection === 0) {
            this.scrollToSection(this.activeSection, true);
          } else {
            this.activeSection--;
            this.scrollToSection(this.activeSection, true);
          }
        },

        // // Move to the next section or the first section if you're on the last section
        moveUp() {
          this.inMove = true;

          if (this.activeSection === this.offsets.length - 1) {
            this.scrollToSection(this.activeSection, true);
          } else {
            this.activeSection++;
            this.scrollToSection(this.activeSection, true);
          }
        },

        // Scrolls to the passed section id if the section exists and the delay is over
        scrollToSection(id, force = false) {
            if(this.inMove && !force) return false;

            this.activeSection = id;
            this.inMove = true;

            // get section and scroll into view if it exists
            let section = document.getElementsByTagName('section')[id];
            if(section) {
                document.getElementsByTagName('section')[id].scrollIntoView({behavior: 'smooth'});
            }

            setTimeout(() => {
                this.inMove = false;
            }, this.inMoveDelay);
        },

        // Handles the 'touchstart' event on mobile devices
        touchStart(e) {
            e.preventDefault();

            this.touchStartY = e.touches[0].clientY;
        },

        // Handles the 'touchmove' event on mobile device
        touchMove(e) {
            if(this.inMove) return false;
            e.preventDefault();

            const currentY = e.touches[0].clientY;

            if(this.touchStartY < currentY) {
                this.moveDown();
            } else {
                this.moveUp();
            }

            this.touchStartY = 0;
            return false;
        },

        // handle a change in fullscreen (F11)
        handleFullscreenChange() {
          const isFullscreen = window.innerHeight === screen.height;
          const mediaQuery = window.matchMedia('(max-width: 1000px)');

          if (isFullscreen) {
            document.querySelectorAll('.moreContentHalf').forEach((el) => {
              if (mediaQuery.matches) {
                document.querySelectorAll('.moreContentHalf').forEach((el) => {
                  el.style.margin = '25px 50px';
                });
              } else {
                el.style.margin = '25px 140px';
              }
          });
          } else {
            document.querySelectorAll('.moreContentHalf').forEach((el) => {
              if (mediaQuery.matches) {
                document.querySelectorAll('.moreContentHalf').forEach((el) => {
                  el.style.margin = '25px 50px';
                });
              } else {
                el.style.margin = '25px 180px';
              }
          });
          }

        },

        disableMoveFunctions() {
          window.removeEventListener('keydown', this.handleKeyDown);
          window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM);
          window.removeEventListener('mousewheel', this.handleMouseWheel, { passive: false });

          window.removeEventListener('touchstart', this.touchStart, { passive: false });
          window.removeEventListener('touchmove', this.touchMove, { passive: false });
        },
        enableMoveFunctions() {
          window.addEventListener('keydown', this.handleKeyDown);
          window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM);
        window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false });

        window.addEventListener('touchstart', this.touchStart, { passive: false });
        window.addEventListener('touchmove', this.touchMove, { passive: false });
        },
        handleKeyDown(event) {
          if (event.key === 'ArrowDown') {
            this.moveUp();
          } else if (event.key === 'ArrowUp') {
            this.moveDown();
          }
        }
    },

    mounted() {
        // Take user to the top when refreshed
        window.onbeforeunload = function () {
          window.scrollTo(0, 0);
        };

      AOS.init();

      this.calculateSectionOffsets();

      window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM);
      window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false });

      window.addEventListener('touchstart', this.touchStart, { passive: false });
      window.addEventListener('touchmove', this.touchMove, { passive: false });



      window.addEventListener('keydown', this.handleKeyDown);

      window.addEventListener('resize', this.handleFullscreenChange);
    },

    unmounted() {
        window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
        window.removeEventListener('mousewheel', this.handleMouseWheel, { passive: false });  // Other browsers

        window.removeEventListener('touchstart', this.touchStart); // mobile devices
        window.removeEventListener('touchmove', this.touchMove); // mobile devices
    },
}
</script>

<template>

    <!-- VIEWPORT 1 - INTRO -->
    <section class="fullpage" v-if="!state.slideshowActive">
        <TheIntro />
    </section>

    <!-- VIEWPORT 2 - OIL TANKER ACTIONS -->
    <section class="fullpage">
        <TankersOne @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <!-- VIEWPORT 3 - SCROLLING TANKER -->
    <section class="fullpage">
      <TankersTwo @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <section class="fullpage" style="background-color: black;">
        <XRChomsky @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <!-- Mitch -->
    <section class="fullpage">
        <Mitch @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <section class="fullpage">
      <TheFestivals @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <section class="fullpage">
      <ProtestsOne @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <section class="fullpage">
      <ProtestsTwo @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <section class="fullpage" v-if="!state.slideshowActive">
      <img src="./assets/images/protests4/protests-1.jpg" alt="" class="fullScreenImage">
    </section>

    <section class="fullpage">
      <OrangeTez @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <section class="fullpage">
      <EbenDisobey @disable-move-functions="disableMoveFunctions" @enable-move-functions="enableMoveFunctions" />
    </section>

    <section class="fullpage" v-if="!state.slideshowActive">
      <TheOutro />
    </section>

</template>

<style>
/* Fonts */
/* @font-face {
  font-family: 'Elza Black';
  src: url('./assets/fonts/ElzaTrial-Black.otf') format('opentype');
}

@font-face {
  font-family: 'Elza Medium Oblique';
  src: url('./assets/fonts/ElzaTrial-CondMediumOblique.otf') format('opentype');
}

@font-face {
  font-family: 'Elza Medium';
  src: url('./assets/fonts/ElzaTextTrial-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Elza Light';
  src: url('./assets/fonts/ElzaTextTrial-Light.otf') format('opentype');
} */

body {
  margin: 0;
  color: black;
  overflow: hidden;
}

.fullpage {
  height: 100vh;
  width: 100%;
}

/* --------------------------- */
/* ----- CUSTOM ELEMENTS ----- */
/* --------------------------- */
.container {
    position: relative;
    height: 100%;
    width: 100%;
}

/* behind an initial fullscreen image */
.moreContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

/* used alongside the above, defines the size of a fullscreen image */
.fullScreenImage {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

/* split the morecontent into half, for 2 page content */
.moreContentHalf {
    position: relative;
    width: 50%;
    margin: 25px 140px;
    /* make it 180 on non-f11 screens */
}

.moreContentHalfText {
  position: relative;
  width: 50%;
  margin: 25px 100px;
}

.moreContentHalfText > div > img {
  width: 100%;
}

/*  */
.halfWidthImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.imageTextOverlay {
    position: absolute;
    top: 0;
}

.overlayImageRight, .overlayImageLeft {
    width: 100%;
    height: auto;
}

.overlayImageRight {
    right: 0;
}
.overlayImageLeft {
    left: 0;
}

.twoPageImgContainer {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.twoPageImgContainer > img {
  position: absolute;
}

.twoPageImgContainer.showImage > img {
    animation: scrollImage 5s ease-in-out forwards;
}

.textContainerFull {
    width: 60%;
    margin: 4em auto;
}

.textGapContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@keyframes scrollImage {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}

/* typography */
.typedText {
  font-size: 1.25em;
  line-height: 1.25em;
}

.typedHeader {
  font-family: 'Elza', sans-serif;
  font-weight: 600;
}

.typedContent {
  font-family: 'Elza', sans-serif;
  line-height: 1.2em;
}

.typedSignature {
  font-family: 'Elza', sans-serif;
  color: white;
  background-color: black;
  padding: 5px;
  display: grid;
  width: fit-content;
  text-align: right;
  font-size: 1em !important;
  margin-left: auto;
  font-weight: 800;
}

.typedSignature span {
  font-size: 1.3em !important;
}

.imgPair {
  position: relative;
}

.imgPair .imgPairImg {
  position: absolute;
  top: 0;
  left: 0;
}

div:focus {
  outline: none;
}
</style>