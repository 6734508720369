<script>
import ProgressBar from '../elements/ProgressBar.vue'
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 52000 : 44000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            festivalstext1: null,
            festivalstext2: null,
            festivals1: null,
            festivals2: null,
            festivalsgroup1: null,
            festivals3: null,
            festivals4: null,
            festivals5: null,
            hasShown: null,
        }
    },
    methods: {
        initAnimation() {
            this.hasShown = false;
            setTimeout(() => {
                this.festivals1.style.opacity = 1;
                this.festivals1.setAttribute("data-aos", "fade-in")

                setTimeout(() => {
                    this.festivalstext1.style.opacity = 1;
                    this.festivalstext1.setAttribute("data-aos", "fade-in")

                    setTimeout(() => {
                        this.festivalstext2.style.opacity = 1;
                        this.festivalstext2.setAttribute("data-aos", "fade-in")

                        setTimeout(() => {
                            this.festivals2.style.opacity = 1;
                            this.festivals2.setAttribute("data-aos", "fade-in")

                            setTimeout(() => {
                                this.festivalsgroup1.setAttribute("data-aos", "fade-out")
                                this.festivalsgroup1.style.opacity = 0;

                                setTimeout(() => {
                                    this.festivals3.style.opacity = 1;
                                    this.festivals3.setAttribute("data-aos", "fade-in")

                                    setTimeout(() => {
                                        this.festivals3.setAttribute("data-aos", "fade-out")
                                        this.festivals3.style.opacity = 0;

                                        setTimeout(() => {
                                            this.festivals4.style.opacity = 1;
                                            this.festivals4.setAttribute("data-aos", "fade-in")

                                            setTimeout(() => {
                                                this.festivals4.setAttribute("data-aos", "fade-out")
                                                this.festivals4.style.opacity = 0;

                                                setTimeout(() => {
                                                    this.festivals5.style.opacity = 1;
                                                    this.festivals5.setAttribute("data-aos", "fade-in")
                                                    this.hasShown = true;

                                                    if(this.state.slideshowActive) {
                                                        setTimeout(() => {
                                                        const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                                        window.dispatchEvent(event);
                                                        }, 8000); // 8 seconds delay
                                                    }

                                                // amount of time final image is on screen.
                                                }, 100);

                                            // amount of time second fullscreen image is on screen
                                            }, 5000);

                                        // amount of time between first and second full image
                                        }, 100);

                                    // amount of time first fullscreen image is on screen
                                    }, 5000);

                                // white screen between first festival group and first fullscreen image
                                }, 1000);

                            // time first festivals group is on screen before fading
                            }, 8000);

                        // between first image fade in and first text fade in
                        }, 9000);

                    // between first image fade in and first text fade in
                    }, 9000);

                // between first image fade in and first text fade in
                }, 3000);

            // time that the page is empty to first image fading in
            }, 2000);
        },
        resetAnimation() {
            const elements = [
                this.festivalstext1,
                this.festivalstext2,
                this.festivals1,
                this.festivals2,
                this.festivals3,
                this.festivals4,
                this.festivals5,
            ];
            elements.forEach((element) => {
                element.style.opacity = 0;
            });
            this.festivalsgroup1.style.opacity = 1;
            this.$refs.progressBar.reset();
            this.initAnimation();
        }
    },
    mounted() {
        this.$aos.init();

        this.festivalstext1 = this.$refs.festivalstext1;
        this.festivalstext2 = this.$refs.festivalstext2;
        this.festivals1 = this.$refs.festivals1;
        this.festivals2 = this.$refs.festivals2;
        this.festivalsgroup1 = this.$refs.festivalsgroup1;
        this.festivals3 = this.$refs.festivals3;
        this.festivals4 = this.$refs.festivals4;
        this.festivals5 = this.$refs.festivals5;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;

                    if (this.hasShown == null) {
                        this.initAnimation();
                    }

                    window.addEventListener("keydown", resetAnimationOnKeyDown);
                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            })
        }, options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },
}
</script>

<template>
    <div ref="container" class="container">
        <div class="moreContent" ref="festivalsgroup1">
            <div class="moreContentHalf" style="display: flex; justify-content: center; flex-direction: column;">
                <img
                ref="festivalstext1"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/festivals/text/festivals-text-1.png"
                style="opacity: 0; width: 80%; margin-left: -20px;"
                alt="">
                <img
                ref="festivals1"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                src="../../assets/images/festivals/festivals-1.jpg"
                alt=""
                style="opacity: 0; width: 100%;">
                <img
                ref="festivalstext2"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/festivals/text/festivals-text-2.png"
                style="opacity: 0; width: 80%; margin-left: 130px;"
                alt="">
            </div>
            <div class="moreContentHalf">
                <img
                ref="festivals2"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/festivals/festivals-2.jpg"
                style="opacity: 0"
                alt="">
            </div>
        </div>
        <div class="moreContent">
            <img
            ref="festivals3"
            class="fullScreenImage"
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/festivals/festivals-3.jpg"
            alt="">
        </div>
        <div class="moreContent">
            <img
            ref="festivals4"
            class="fullScreenImage"
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/festivals/festivals-4.jpg"
            alt="">
        </div>
        <div class="moreContent">
            <img
            ref="festivals5"
            class="fullScreenImage"
            data-aos="fade-in"
            data-aos-duration="2000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/festivals/festivals-5.jpg"
            alt="">
        </div>

        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>