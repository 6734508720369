<script>
import ProgressBar from '../elements/ProgressBar.vue';
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 31000 : 23000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            containerClass: '',
            mitch1: null,
            mitchtext1: null,
            mitchtext2: null,
            hasShown: null,
        }
    },
    methods: {
        initAnimation() {
            this.hasShown = false;
            setTimeout(() => {
                this.containerClass = 'showImage';

                setTimeout(() => {
                    this.mitch1.setAttribute("data-aos", "fade-out");
                    this.mitch1.style.opacity = 0;

                    setTimeout(() => {
                        this.mitchtext1.style.opacity = 1;
                        this.mitchtext1.setAttribute("data-aos", "fade-in")

                        setTimeout(() => {
                            this.mitchtext2.style.opacity = 1;
                            this.mitchtext2.setAttribute("data-aos", "fade-in")
                            this.hasShown = true;

                            if(this.state.slideshowActive) {
                                setTimeout(() => {
                                const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                window.dispatchEvent(event);
                                }, 8000); // 8 seconds delay
                            }

                        // time taken for second para to fade in after first
                        }, 7000)

                    // time taken for first paragraph to fade in from white page
                    }, 2000)

                // time taken from when the mitch starts to scroll down to the image fading out (scrolling down takes 5 seconds, so 5000 + time spent on bottom half).
                }, 9000)

            // time spent at the top of mitch before scrolling down
            }, 5000);
        },

        resetAnimation() {
            const elements = [
                this.mitchtext1,
                this.mitchtext2,
            ];
            elements.forEach((element) => {
                element.style.opacity = 0;
            });
            this.mitch1.style.opacity = 1;
            this.containerClass = '';
            this.$refs.progressBar.reset();
            this.initAnimation();
        }
    },
    mounted() {

        this.$aos.init();

        this.mitch1 = this.$refs.mitch1;
        this.mitchtext1 = this.$refs.mitchtext1;
        this.mitchtext2 = this.$refs.mitchtext2;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            root: document.querySelector('container'),
            rootMargin: '-100px',
            threshhold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;
                    if (this.hasShown == null) {
                        this.initAnimation();
                    }
                    window.addEventListener("keydown", resetAnimationOnKeyDown);
                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            });
        }, options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },

}
</script>

<template>
    <div ref="container" class="twoPageImgContainer" :class="containerClass">
        <img ref="mitch1" src="../../assets/images/mitch/mitch-1.jpg" alt="">
        <div class="textContainerFull" ref="mitchtextgroup">
            <img
            ref="mitchtext1"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            src="../../assets/images/mitch/text/mitch-text-1.png"
            alt=""
            style="opacity: 0"
            >
            <img
            ref="mitchtext2"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            src="../../assets/images/mitch/text/mitch-text-2.png"
            alt=""
            style="opacity: 0"
            >
        </div>
        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>