<script>
import ProgressBar from '../elements/ProgressBar.vue';
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 39000 : 31000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            tanker1: null,
            tanker2: null,
            tanker3: null,
            tanker4: null,
            tanker5: null,
            tankertext1: null,
            tankertext2: null,
            tankertext3: null,
            hasShown: null,
        }
    },
    methods: {
        initAnimation() {
            this.hasShown = false;
            setTimeout(() => {
                this.$aos.refresh();
                this.tanker1.setAttribute("data-aos", "fade-out");
                this.tanker1.style.opacity = 0;

                setTimeout(() => {
                    this.tanker2.style.opacity = 1;
                    this.tanker2.setAttribute("data-aos", "fade-in");

                    setTimeout(() => {
                        this.tanker3.style.opacity = 1;
                        this.tanker3.setAttribute("data-aos", "fade-in");

                        setTimeout(() => {
                            this.tankertext1.style.opacity = 1;
                            this.tankertext1.setAttribute("data-aos", "fade-in");

                            setTimeout(() => {
                                this.tanker2.setAttribute("data-aos", "fade-out");
                                this.tanker3.setAttribute("data-aos", "fade-out");
                                this.tankertext1.setAttribute("data-aos", "fade-out");
                                this.tanker2.style.opacity = 0;
                                this.tanker3.style.opacity = 0;
                                this.tankertext1.style.opacity = 0;

                                setTimeout(() => {
                                    this.tanker4.style.opacity = 1
                                    this.tanker4.setAttribute("data-aos", "fade-in");

                                    setTimeout(() => {
                                        this.tankertext2.style.opacity = 1
                                        this.tankertext2.setAttribute("data-aos", "fade-in");

                                        setTimeout(() => {
                                            this.tanker5.style.opacity = 1;
                                            this.tanker5.setAttribute("data-aos", "fade-in");

                                            setTimeout(() => {
                                                this.tankertext3.style.opacity = 1;
                                                this.tankertext3.setAttribute("data-aos", "fade-in");
                                                this.hasShown = true;

                                                if(this.state.slideshowActive) {
                                                    setTimeout(() => {
                                                    const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                                    window.dispatchEvent(event);
                                                    }, 8000); // 8 seconds delay
                                                }

                                            // time between right image fading in, and right text fading in, second set.
                                            }, 3000)

                                        // time between left image text fading in, and right image fading in, second set.
                                        }, 3000)

                                    // time between left image fading in, and its text overlaying, second set.
                                    }, 3000)

                                // time between first set fading out, and second left fading in (white screen space)
                                }, 3000)

                            // time that both images in the first set, and text, are held on screen before fading out.
                            }, 5000);

                        // time between right image fading in, and right image text fading in, first set.
                        }, 3000);

                    // time between left image fading in and right image fading it, first set.
                    }, 3000);

                // time between first image fading out and left image to load, first set.
                }, 3000);

            // amount of time first image (full width) is on the page, first image.
            }, 5000);
        },

        resetAnimation() {
            const elements = [
                this.tanker2,
                this.tanker3,
                this.tankertext1,
                this.tanker4,
                this.tankertext2,
                this.tanker5,
                this.tankertext3,
            ];

            elements.forEach((element) => {
                element.style.opacity = 0;
            });

            this.tanker1.style.opacity = 1;

            this.$refs.progressBar.reset();
            this.initAnimation();
        }
    },

    mounted() {

        localStorage.setItem('isAudioPlayed', false)

        this.$aos.init();
        this.tanker1 = this.$refs.tanker1;
        this.tanker2 = this.$refs.tanker2;
        this.tanker3 = this.$refs.tanker3;
        this.tanker4 = this.$refs.tanker4;
        this.tanker5 = this.$refs.tanker5;
        this.tankertext1 = this.$refs.tankertext1;
        this.tankertext2 = this.$refs.tankertext2;
        this.tankertext3 = this.$refs.tankertext3;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;

                    const audio = new Audio('/itgotm.wav');
                    const audioBtn = document.getElementById('audio');
                    const isAudioPlayed = JSON.parse(localStorage.getItem('isAudioPlayed'));

                    audio.addEventListener('ended', () => {
                        audio.currentTime = 0;
                        audio.play();
                    });

                    audioBtn.addEventListener('click', () => {
                        audio.play()
                            .catch(error => {
                            console.error('Failed to play audio:', error);
                        });
                        localStorage.setItem('isAudioPlayed', true)
                    });

                    if (isAudioPlayed !== true) {
                        audioBtn.click();
                    }

                    if (this.hasShown == null) {
                        this.initAnimation();
                    }

                window.addEventListener("keydown", resetAnimationOnKeyDown);

                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            });
        }, options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },
};
</script>

<template>
    <div class="container" ref="container" tabindex="0">
        <img
        class="fullScreenImage"
        ref="tanker1"
        data-aos="fade-in"
        data-aos-duration="1500"
        data-aos-once="true"
        data-aos-offset="200"
        src="../../assets/images/tankers/tankers-1.jpg"
        style="opacity: 1;"
        alt="">

        <div class="moreContent">
            <div class="moreContentHalf">
                <img
                ref="tanker2"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/tankers/tankers-2.jpg"
                style="opacity: 0"
                alt="">
            </div>
            <div class="moreContentHalf">
                    <img
                    ref="tanker3"
                    data-aos="fade-in"
                    data-aos-duration="1500"
                    data-aos-once="true"
                    data-aos-offset="200"
                    class="halfWidthImage"
                    src="../../assets/images/tankers/tankers-3.jpg"
                    style="opacity: 0"
                    alt="">
                    <div class="imageTextOverlay">
                        <img
                        ref="tankertext1"
                        data-aos="fade-in"
                        data-aos-duration="1500"
                        data-aos-once="true"
                        data-aos-offset="200"
                        class="overlayImageRight"
                        src="../../assets/images/tankers/text/tankers-text-1.png"
                        style="opacity: 0"
                        alt="">
                    </div>
            </div>
        </div>

        <div class="moreContent">
            <div class="moreContentHalf">
                <img
                ref="tanker4"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/tankers/tankers-4.jpg"
                style="opacity: 0"
                alt="">
                <div class="imageTextOverlay">
                    <img
                    ref="tankertext2"
                    data-aos="fade-in"
                    data-aos-duration="1500"
                    data-aos-once="true"
                    data-aos-offset="200"
                    class="overlayImageLeft"
                    src="../../assets/images/tankers/text/tankers-text-2.png"
                    style="opacity: 0"
                    alt="">
                </div>
            </div>
            <div class="moreContentHalf">
                <img
                ref="tanker5"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/tankers/tankers-5.jpg"
                style="opacity: 0"
                alt="">
                <div class="imageTextOverlay">
                    <img
                    ref="tankertext3"
                    data-aos="fade-in"
                    data-aos-duration="1500"
                    data-aos-once="true"
                    data-aos-offset="200"
                    class="overlayImageRight"
                    src="../../assets/images/tankers/text/tankers-text-3.png"
                    style="opacity: 0"
                    alt="">
                </div>
            </div>
        </div>
        <div style="display: none;">
            <button id="audio"></button>
        </div>

        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>