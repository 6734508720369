<script>
import ProgressBar from "../elements/ProgressBar.vue";
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 51000 : 43000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            containerClass: '',
            tanker6: null,
            tankertext5: null,
            tankertext6: null,
            tankertext7: null,
            tankertext8: null,
            tankertextgroup: null,
            protests1: null,
            protests2: null,
            hasShown: null,
        }
    },
    methods: {
        initAnimation() {
            this.hasShown = false;
            setTimeout(() => {
                this.containerClass = 'showImage';

                setTimeout(() => {
                    this.tanker6.setAttribute("data-aos", "fade-out");
                    this.tanker6.style.opacity = 0;

                    setTimeout(() => {
                        this.tankertext5.style.opacity = 1;
                        this.tankertext5.setAttribute("data-aos", "fade-in");

                        setTimeout(() => {
                            this.tankertext6.style.opacity = 1;
                            this.tankertext6.setAttribute("data-aos", "fade-in");

                            setTimeout(() => {
                                this.tankertext7.style.opacity = 1;
                                this.tankertext7.setAttribute("data-aos", "fade-in");

                                setTimeout(() => {
                                    this.tankertext8.style.opacity = 1;
                                    this.tankertext8.setAttribute("data-aos", "fade-in");

                                    setTimeout(() => {
                                        this.tankertextgroup.setAttribute("data-aos", "fade-out");
                                        this.tankertextgroup.style.opacity = 0;

                                        setTimeout(() => {
                                            this.protests1.style.opacity = 1;
                                            this.protests1.setAttribute("data-aos", "fade-in");

                                            setTimeout(() => {
                                                this.protests2.style.opacity = 1;
                                                this.protests2.setAttribute("data-aos", "fade-in");
                                                this.hasShown = true;

                                                if(this.state.slideshowActive) {
                                                    setTimeout(() => {
                                                    const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                                    window.dispatchEvent(event);
                                                    }, 8000); // 8 seconds delay
                                                }

                                            // time taken for right image to fade in after left
                                            }, 3000)

                                        // time taken from blank page after text, to left image fading in
                                        }, 3000)

                                    // time that all of the text is on the page before fading out
                                    }, 7000)

                                // time taken for fourth paragraph to fade in from white page
                                }, 5000);

                            // time taken for third paragraph to fade in from white page
                            }, 5000);

                        // time taken for second paragraph to fade in from white page
                        }, 7000);

                    // time taken for first paragraph to fade in from white page
                    }, 2000);

                // time taken from when the image starts to scroll down to the image fading out (scrolling down takes 5 seconds, so 5000 + time spent on bottom half)
                }, 8000);

            // time spent at the top of the double height image before scrolling down.
            }, 3000);
        },

        resetAnimation() {
            const elements = [
                this.tankertext5,
                this.tankertext6,
                this.tankertext7,
                this.tankertext8,
                this.protests1,
                this.protests2,
            ];
            this.tanker6.style.opacity = 1;
            this.tankertextgroup.style.opacity = 1;
            elements.forEach((element) => {
                element.style.opacity = 0;
            });
            this.$refs.progressBar.reset();
            this.containerClass = '';
            this.initAnimation();
        }
    },
    mounted() {
        this.$aos.init();

        this.tanker6 = this.$refs.tanker6;
        this.tankertext5 = this.$refs.tankertext5;
        this.tankertext6 = this.$refs.tankertext6;
        this.tankertext7 = this.$refs.tankertext7;
        this.tankertext8 = this.$refs.tankertext8;
        this.tankertextgroup = this.$refs.tankertextgroup;
        this.protests1 = this.$refs.protests1;
        this.protests2 = this.$refs.protests2;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                this.isInView = true;
                if (this.hasShown == null) {
                    this.initAnimation();
                }
                window.addEventListener("keydown", resetAnimationOnKeyDown);
                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            });
        }, options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },
}
</script>

<template>
    <div ref="container" class="twoPageImgContainer" :class="containerClass">
        <img ref="tanker6" src="../../assets/images/tankers/tankers-6.jpg" alt="">
        <div class="textContainerFull" ref="tankertextgroup">
            <img
            ref="tankertext5"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            src="../../assets/images/tankers/text/tankers-text-5.png"
            alt=""
            style="opacity: 0"
            >
            <img
            ref="tankertext6"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            src="../../assets/images/tankers/text/tankers-text-6.png"
            alt=""
            style="opacity: 0"
            >
            <img
            ref="tankertext7"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            src="../../assets/images/tankers/text/tankers-text-7.png"
            alt=""
            style="opacity: 0"
            >
            <img
            ref="tankertext8"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            src="../../assets/images/tankers/text/tankers-text-8.png"
            alt=""
            style="opacity: 0"
            >


        </div>
        <div class="moreContent">
            <div class="moreContentHalf">
                <img
                ref="protests1"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/protests/protests-1.jpg"
                style="opacity: 0"
                alt="">
            </div>
            <div class="moreContentHalf">
                <img
                ref="protests2"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/protests/protests-2.jpg"
                style="opacity: 0"
                alt="">
            </div>
        </div>
        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>