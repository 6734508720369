<script>
import ProgressBar from '../elements/ProgressBar.vue'
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 52000 : 44000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            protests1: null,
            protests2: null,
            proteststext1: null,
            proteststext2: null,
            proteststext3: null,
            contentgroup1: null,
            milk0: null,
            milk1: null,
            milk2: null,
            milk3: null,
            milk4: null,
            milk5: null,
            milk6: null,
            hasShown: null,
        }
    },
    methods: {
        initAnimation() {
            this.hasShown = false;
            setTimeout(() => {
                this.protests1.style.opacity = 1;
                this.protests1.setAttribute("data-aos", "fade-in")

                setTimeout(() => {
                    this.protests2.style.opacity = 1;
                    this.protests2.setAttribute("data-aos", "fade-in")

                    setTimeout(() => {
                        this.proteststext1.style.opacity = 1;
                        this.proteststext1.setAttribute("data-aos", "fade-in")

                        setTimeout(() => {
                            this.proteststext2.style.opacity = 1;
                            this.proteststext2.setAttribute("data-aos", "fade-in")

                            setTimeout(() => {
                                this.proteststext3.style.opacity = 1;
                                this.proteststext3.setAttribute("data-aos", "fade-in")
                                this.protests1.style.filter = "grayscale(0%)";

                                setTimeout(() => {
                                    this.contentgroup1.setAttribute("data-aos", "fade-out");
                                    this.contentgroup1.style.opacity = 0;

                                    setTimeout(() => {
                                        this.milk0.setAttribute("data-aos", "fade-in");
                                        this.milk0.style.opacity = 1;

                                        setTimeout(() => {
                                            this.milk1.setAttribute("data-aos", "fade-in");
                                            this.milk1.style.opacity = 1;

                                            setTimeout(() => {
                                                this.milk2.setAttribute("data-aos", "fade-in");
                                                this.milk2.style.opacity = 1;

                                                setTimeout(() => {
                                                    this.milk3.setAttribute("data-aos", "fade-in");
                                                    this.milk3.style.opacity = 1;

                                                    setTimeout(() => {
                                                        this.milk4.setAttribute("data-aos", "fade-in");
                                                        this.milk4.style.opacity = 1;

                                                        setTimeout(() => {
                                                            this.milk5.setAttribute("data-aos", "fade-in");
                                                            this.milk5.style.opacity = 1;

                                                            setTimeout(() => {
                                                                this.milk6.setAttribute("data-aos", "fade-in");
                                                                this.milk6.style.opacity = 1;
                                                                this.hasShown = true;

                                                                if(this.state.slideshowActive) {
                                                                    setTimeout(() => {
                                                                    const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                                                    window.dispatchEvent(event);
                                                                    }, 8000); // 8 seconds delay
                                                                }

                                                            // time final milk text
                                                            }, 2000)

                                                        // time 4th milk text
                                                        }, 2000)

                                                    // time 4th milk text
                                                    }, 2000)

                                                // time 3rd milk text
                                                }, 2000)

                                            // time 2nd milk text
                                            }, 2000)

                                        // time 1st milk text
                                        }, 4000)

                                    // time page is white before animal protest pics.
                                    }, 2000)

                                // time between everything showing and fade to white
                                }, 7000)

                            // time between second and third text
                            }, 5000)

                        // time between first and second text
                        }, 7000)

                    // time between protest image and first text
                    }, 3000)

                // time between mcdonalds police and right protest image.
                }, 3000)

            // time between white page and mcdonalds police
            }, 1000)
        },
        resetAnimation() {
            const elements = [
                this.protests1,
                this.protests2,
                this.proteststext1,
                this.proteststext2,
                this.proteststext3,
                this.milk0,
            ];
            elements.forEach((element) => {
                element.style.opacity = 0;
            });
            this.contentgroup1.style.opacity = 1;
            this.protests1.style.filter = "grayscale(100%)"
            this.$refs.progressBar.reset();
            this.initAnimation();
        }
    },
    mounted() {
        this.$aos.init();

        this.protests1 = this.$refs.protests1;
        this.protests2 = this.$refs.protests2;
        this.proteststext1 = this.$refs.proteststext1;
        this.proteststext2 = this.$refs.proteststext2;
        this.proteststext3 = this.$refs.proteststext3;
        this.contentgroup1 = this.$refs.contentgroup1;
        this.milk0 = this.$refs.milk0;
        this.milk1 = this.$refs.milk1;
        this.milk2 = this.$refs.milk2;
        this.milk3 = this.$refs.milk3;
        this.milk4 = this.$refs.milk4;
        this.milk5 = this.$refs.milk5;
        this.milk6 = this.$refs.milk6;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;
                    if (this.hasShown == null) {
                        this.initAnimation();
                    }
                    window.addEventListener("keydown", resetAnimationOnKeyDown);
                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            })
        }, options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },
}
</script>

<template>
    <div ref="container" class="container">
        <div class="moreContent" ref="contentgroup1">
            <div class="moreContentHalf">
                <img
                ref="protests1"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/protests2/protests-1.jpg"
                style="opacity: 0; filter: grayscale(100%);"
                alt="">
            </div>
            <div class="moreContentHalf">
                <img
                ref="protests2"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/protests2/protests-2.jpg"
                style="opacity: 0; width: 100%;"
                alt="">
                <img
                ref="proteststext1"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/protests2/text/protests-text-1.png"
                style="opacity: 0; width: 80%; margin-left: -24px;"
                alt="">
                <img
                ref="proteststext2"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/protests2/text/protests-text-2.png"
                style="opacity: 0; width: 50%; margin-left: -24px;"
                alt="">
                <img
                ref="proteststext3"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/protests2/text/protests-text-3.png"
                style="opacity: 0; width: 80%; margin-left: -24px;"
                alt="">
            </div>
        </div>
        <div class="moreContent">
            <img
            ref="milk0"
            class="fullScreenImage"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/protests2/milk0.jpg"
            alt="">
            <img
            ref="milk1"
            class="fullScreenImage overlayFullscreen"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/protests2/milk1.jpg"
            alt="">
            <img
            ref="milk2"
            class="fullScreenImage overlayFullscreen"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/protests2/milk2.jpg"
            alt="">
            <img
            ref="milk3"
            class="fullScreenImage overlayFullscreen"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/protests2/milk3.jpg"
            alt="">
            <img
            ref="milk4"
            class="fullScreenImage overlayFullscreen"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/protests2/milk4.jpg"
            alt="">
            <img
            ref="milk5"
            class="fullScreenImage overlayFullscreen"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/protests2/milk5.jpg"
            alt="">
            <img
            ref="milk6"
            class="fullScreenImage overlayFullscreen"
            data-aos="fade-in"
            data-aos-duration="1000"
            data-aos-once="true"
            style="opacity: 0"
            src="../../assets/images/protests2/milk6.jpg"
            alt="">
        </div>
        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>

<style scoped>
.overlayFullscreen {
    position: absolute;
    height: 100vh;
    width: 100vw;
}
</style>