<script>
import ProgressBar from "../elements/ProgressBar.vue";
import { inject, computed } from 'vue';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const time = computed(() => {
            return state.slideshowActive ? 45000 : 33000;
        })
        return { state, time };
    },
    data() {
        return {
            isInView: false,
            containerClass: '',
        }
    },
    mounted() {
        this.$aos.init();

        const protests1 = this.$refs.protests1;
        const proteststext1 = this.$refs.proteststext1;
        const proteststext2 = this.$refs.proteststext2;
        const proteststext3 = this.$refs.proteststext3;
        const proteststext4 = this.$refs.proteststext4;

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;

                    setTimeout(() => {
                        protests1.setAttribute("data-aos", "fade-in");
                        protests1.style.opacity = 1;

                        setTimeout(() => {
                            proteststext1.setAttribute("data-aos", "fade-in");
                            proteststext1.style.opacity = 1;

                            setTimeout(() => {
                                proteststext2.setAttribute("data-aos", "fade-in");
                                proteststext2.style.opacity = 1;

                                setTimeout(() => {
                                    proteststext3.setAttribute("data-aos", "fade-in");
                                    proteststext3.style.opacity = 1;

                                    setTimeout(() => {
                                        proteststext4.setAttribute("data-aos", "fade-in");
                                        proteststext4.style.opacity = 1;
                                        this.hasShown = true;

                                        if(this.state.slideshowActive) {
                                            setTimeout(() => {
                                            const event = new KeyboardEvent('keydown', {'key':'ArrowDown'});
                                            window.dispatchEvent(event);
                                            }, 8000); // 8 seconds delay
                                        }

                                        // time between text 3 and 4
                                    }, 12000)

                                    // time between text 2 and 3
                                }, 12000)

                                // time between text 1 and 2
                            }, 3000)

                            // time image is on page until text 1 shows up.
                        }, 5000)

                    // time initial page is blank
                    }, 1000)

                }
            });
        },options);
        observer.observe(this.$refs.container);
    },
}

</script>

<template>
    <div ref="container" class="container">
        <div class="moreContent">
            <div class="moreContentHalf" style="display: flex; justify-content: center; flex-direction: column;">
                <p class="typedText typedHeader" ref="proteststext1" style="color: red; margin-bottom: 20px; opacity: 0; width: 130%;">
                    What do you think the future holds for UK climate activist tactics now harsher sentencing and police powers are in place? Would covert actions be justifiable or strategic?
                </p>
                <p class="typedText typedContent" ref="proteststext2" style="opacity: 0; width: 120%;">
                    "For years, our police have already had the powers to arrest individuals who take part in disruptive protests. Many peer-reviewed studies suggest that silencing peaceful yet disruptive dissent will lead to a violent conflict between the state and those protesters who are not grounded in non-violence. The love for all life, the fear of harsh sentences, and the desperation caused by government inaction may well be the holy trinity which leads to covert actions from individuals. If they prove to be the way to push for results, many can be expected to choose this path of action. In the end, nature, animals, and the oceans won't know, nor care, who stops their inevitable destruction.
                </p>
                <p class="typedText typedContent" ref="proteststext3" style="opacity: 0; width: 120%;">
                    Since CASP (Climate Action Support Pathway) was formed in 2022, I have been able to support the most courageous and wonderful activists who have been ready to speak the truth and face the consequences of their actions. Working so closely with activists on their paths through the criminal and civil legal systems of England and Wales, however, has shown the ugly underbelly of our society; the money-driven, inconsistent, and cruel legal operations which work to silence our voices and create an ever more draconian legal landscape which the young activists of the future will be forced to navigate."
                </p>
                <p class="typedText typedSignature" ref="proteststext4" style="opacity: 0;">
                    <span>LEX</span>
                    CLIMATE ACTION SUPPORT PATHWAY
                </p>
            </div>
            <div class="moreContentHalf">
                <img
                ref="protests1"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/protests3/protests-1.jpg"
                style="opacity: 0"
                alt="">
            </div>
        </div>
        <ProgressBar v-if="isInView" :time="time" />
    </div>
</template>