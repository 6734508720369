<script>
import ProgressBar from '../elements/ProgressBar.vue'
import { inject, computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
    components: {
        ProgressBar,
    },
    setup() {
        const state = inject('state');
        const router = useRouter();
        const time = computed(() => {
            return state.slideshowActive ? 53000 : 45000;
        })
        return { state, time, router };
    },
    data() {
        return {
            isInView: false,
            eben0: null,
            eben1: null,
            ebentext1: null,
            ebentext2: null,
            ebentext3: null,
            ebentext4: null,
            hasShown: null,
        }
    },

    methods: {
        initAnimation() {
            this.hasShown = false;

            setTimeout(() => {
                this.$aos.refresh();
                this.eben0.setAttribute("data-aos", "fade-out")
                this.eben0.style.opacity = 0;

                setTimeout(() => {
                    this.ebentext1.style.opacity = 1
                    this.ebentext1.setAttribute("data-aos", "fade-in");

                    setTimeout(() => {
                        this.eben1.style.opacity = 1;
                        this.eben1.setAttribute("data-aos", "fade-in");

                        setTimeout(() => {
                            this.ebentext2.style.opacity = 1;
                            this.ebentext2.setAttribute("data-aos", "fade-in");

                            setTimeout(() => {
                                this.ebentext3.style.opacity = 1;
                                this.ebentext3.setAttribute("data-aos", "fade-in");

                                setTimeout(() => {
                                    this.ebentext4.style.opacity = 1;
                                    this.ebentext4.setAttribute("data-aos", "fade-in");
                                    this.hasShown = true;

                                    if(this.state.slideshowActive) {
                                        setTimeout(() => {
                                            localStorage.setItem('slideshowActive', 'true');
                                            location.reload();
                                        }, 8000); // 8 seconds delay
                                    }

                                // time between 2 text and 3 text
                                }, 8000)

                            // time between 2 text and 3 text
                            }, 18000)

                        // time between image and 2 text
                        }, 5000)

                    // time between 1 text and left image
                    }, 3000)

                // time between blank page and first text
                }, 2000)

            // fullscreen rave to white
            }, 8000)

        },

        resetAnimation() {
            const elements = [
                this.eben1,
                this.ebentext1,
                this.ebentext2,
                this.ebentext3,
                this.ebentext4
            ];

            elements.forEach((element) => {
                element.style.opacity = 0;
            });

            this.eben1.style.opacity = 1;

            this.$refs.progressBar.reset();
            this.initAnimation();

        }
    },

    mounted() {
        this.$aos.init();

        this.eben0 = this.$refs.eben0;
        this.eben1 = this.$refs.eben1;
        this.ebentext1 = this.$refs.ebentext1;
        this.ebentext2 = this.$refs.ebentext2;
        this.ebentext3 = this.$refs.ebentext3;
        this.ebentext4 = this.$refs.ebentext4;

        const resetAnimationOnKeyDown = (event) => {
            if (event.key === 'ArrowLeft' && this.hasShown == true) {
                this.resetAnimation();
            }
        }

        const options = {
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.isInView = true;
                    if (this.hasShown == null) {
                        this.initAnimation();
                    }
                    window.addEventListener("keydown", resetAnimationOnKeyDown);
                } else {
                    window.removeEventListener("keydown", resetAnimationOnKeyDown);
                }
            })
        }, options);
        observer.observe(this.$refs.container);
    },
    watch: {
            hasShown(value) {
            if (value === false) {
                this.$emit('disable-move-functions');
            } else {
                this.$emit('enable-move-functions');
            }
        },
    },
}

</script>

<template>
    <div ref="container" class="container">
        <img
        class="fullScreenImage"
        ref="eben0"
        data-aos="fade-in"
        data-aos-duration="1500"
        data-aos-once="true"
        data-aos-offset="200"
        src="../../assets/images/eben/eben-0.jpg"
        style="opacity: 1;"
        alt="">
        <div class="moreContent">
            <div class="moreContentHalf">
                <img
                ref="eben1"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-once="true"
                data-aos-offset="200"
                class="halfWidthImage"
                src="../../assets/images/eben/eben-1.jpg"
                style="opacity: 0"
                alt="">
            </div>
            <div class="moreContentHalf" style="display: flex; justify-content: center; flex-direction: column;">
                <img
                ref="ebentext1"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/eben/disobey-text-1.png"
                style="opacity: 0; width: 90%;"
                alt="">
                <img
                ref="ebentext2"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/eben/disobey-text-2.png"
                style="opacity: 0; width: 90%;"
                alt="">
                <img
                ref="ebentext3"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/eben/disobey-text-3.png"
                style="opacity: 0; width: 90%;"
                alt="">
                <img
                ref="ebentext4"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-once="true"
                data-aos-offset="200"
                src="../../assets/images/eben/disobey-text-4.png"
                style="opacity: 0; width: 90%;"
                alt="">
            </div>
        </div>
        <ProgressBar v-if="isInView" :time="time" ref="progressBar" />
    </div>
</template>
