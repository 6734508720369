<template>
    <div class="progress-bar" :style="{ width: width }"></div>
  </template>

<script>
export default {
  props: {
    time: {
      type: Number,
      required: true,
    },
  },
  computed: {
    width() {
      const progress = (this.currentTime / this.time) * 100;
      return `${progress}%`;
    },
  },
  data() {
    return {
      currentTime: 0,
      timerId: null,
    };
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  methods: {
    startTimer() {
      this.timerId = setInterval(() => {
        if (this.currentTime >= this.time) {
          clearInterval(this.timerId);
          return;
        }
        this.currentTime += 50;
      }, 50);
    },
    reset() {
      clearInterval(this.timerId);
      this.currentTime = 0;
      this.startTimer();
    },
  },
};
</script>

  <style scoped>
  .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 8px;
    background-color: #ee6b01;
    transition: width 0.5s linear;
  }
  </style>
